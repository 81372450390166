import React, { Fragment, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";

const Home = () => {
  const {
    isAuthenticated,
    getIdTokenClaims,
  } = useAuth0();

  const [iFrame, setIFrame] = useState('');
  let authToken = '';
  getIdTokenClaims().then((data) => {
    authToken = data.__raw;
    fetch(`${process.env.REACT_APP_BASE_URL}user-profiles/me`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }).then((response) => {
      return response.json()
    }).then((data) => {
      fetch(`${process.env.REACT_APP_BASE_URL}dashboards/${data.organizationId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }).then((response) => {
        return response.json()
      }).then((data) => {
        setIFrame(data.powerbiUrl);
      })
    })
  });
  return (
  <Fragment>
    {isAuthenticated ? 
    <iframe title="Dashboard ejemplo" width="100%" style={{height: "calc(100% - 20px)"}} src={iFrame} frameBorder="0" allowFullScreen="true"></iframe>
    :
    <></>
    }
      </Fragment>
  )
};

export default Home;
