import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
//import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import ExternalApi from "./views/ExternalApi";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const App = () => {
  const { isLoading, error, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }
  if (!isAuthenticated) {
    loginWithRedirect();
  }
  window.onpopstate = function(event) {
    logout({ returnTo: window.location.origin })
    // Verifica si es necesario retroceder dos pasos atrás
    if (event.state && event.state.goBackTwoSteps) {
      // Realiza la redirección dos pasos atrás
      loginWithRedirect();
    }
  };
  
  // Agrega una entrada en el historial para detectar el clic en el botón "Atrás"
  window.history.pushState({ goBackTwoSteps: true }, "", "");
  return (
    <Router history={history}>
      {isAuthenticated ? 
        <div id="app" className="d-flex flex-column h-100">
          <NavBar />
          <Container className="flex-grow-1" style={{maxWidth: "1366px"}}>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/profile" component={Profile} />
              <Route path="/external-api" component={ExternalApi} />
            </Switch>
          </Container>
        </div>
        :
        <></>
      }
    </Router>
  );
};

export default App;
